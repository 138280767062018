<template>
  <v-col style="padding-bottom:110px;" v-if="entity != null">
    <v-row>

      <v-col cols="12" sm="9" md="8" lg="7" class="text-center mx-auto header-section mt-5">
        <v-icon class="d-none d-md-flex" style="font-size:5em; margin-bottom:-70px;" color="primary">mdi-delete</v-icon>
        <v-icon class="d-md-none" style="font-size:4em; margin-bottom:-20px;" color="primary">mdi-delete</v-icon>
        
        <template v-if="$route.params.companyId == null">
          <h2 class="mt-1 ml-4 primary--text">Référencer mon activité</h2>
          <br><br><br>
          <small class="font-papyrus">
            Vous souhaitez attirer plus de clients pirates, pour gagner plus de PRT ?
          </small>
        </template>
        <template v-else>
          <h2 class="mt-1 ml-4 primary--text">Supprimer mon activité</h2>
          <br><br><br>
        </template>
      </v-col>

    </v-row>
    <v-row>
      
      <v-col cols="12" sm="11" md="8" lg="5" class="mt-sm-5 mx-auto">
        <v-card class="mb-5">
        <v-list-item class="">
          <v-list-item-avatar height="30" width="30"
              :class="'mr-3'">
            <v-gravatar height="30" style="border-radius:50%; margin-bottom:0px;"
                        :default-img="'retro'" :email="entity.name"/>
          </v-list-item-avatar>
          
          <v-list-item-content>

            <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
              <b href="javascript:" text 
                style="text-transform:none;font-size:0.7em!important;text-decoration:none;white-space:normal;display:inline;" 
                    :class="'pa-0'">
                    {{ entity.name }}
              </b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      </v-col>

    </v-row>
    <v-row>

      <v-col cols="12" sm="11" md="5" lg="4" class="mt-sm-5 mx-auto text-center">
        <p class="font-papyrus red--text">
          Voulez-vous vraiment supprimer<br>cette activité ? 
        </p>
        <br>
        <v-btn class="mr-5" rounded  :to="'/company/'+$route.params.companyId">annuler</v-btn>
        <v-btn color="red" rounded elevation="0">Oui, supprimer</v-btn>
      </v-col>

    </v-row>
  </v-col>
</template>
  
  
  <script>
  //import axios from "axios"
import Gravatar from 'vue-gravatar'
  
  export default {
    name: 'home',
    props: [],
    components: {
      'v-gravatar' : Gravatar,
    },
    data: () => ({
      showDialog: false,
      entity: null,
      commandeEdit: null
    }),
    mounted: async function(){
      this.$store.state.auth.user.companies.forEach((company)=>{
        console.log("company", company)
        if(company._id == this.$route.params.companyId)
          this.entity = company
      })
      console.log("this.entity = company", this.entity)
    },
    methods: {
      
    },
    watch: {
    }
    
  }
  </script>
  